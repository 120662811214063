$blue: rgb(0, 85, 185);
$black: rgb(0, 0, 0);
$grey: rgba(0, 0, 0, .1);
$white: rgb(255, 255, 255);



$portair-green-atlantis: #8cc739;
$portair-blue-endeavour: #085dad;
$portair-blue-scooter: #21bede;
$portair-blue-picton: #31c3e7;

$water-blue: #42c0fb;
$electricity-yellow: #ffe53c;
$reox-dark: $portair-blue-endeavour;
$reox-light: $portair-blue-scooter;
$dull-white: rgba(255, 255, 255, .75);
