@import "@/styles/globals";











































































































































@import '../styles/bootstrap-override';
@import "~noty/src/noty.scss";
@import "~noty/src/themes/bootstrap-v4.scss";
@import '../styles/opensans';
@import '../styles/vue';

// vertical-center a div
html {
  height: 100%;
}

body {
  font-family: 'open_sansregular';
  height: 100%;
}

em {
  font-family: 'open_sansitalic';
}

strong {
  font-family: 'open_sansbold';

  em {
    font-family: 'open_sanssemibold_italic';
  }
}

.vertical-center {
  font: 0/0 a; // remove the gap between inline(-block) elements
  height: 100%;
  margin-bottom: 0; // Remove the default bottom margin of .jumbotron
  text-align: center;  // align the inline(-block) elements horizontally
  width: 100%;

  > .container {
    display: inline-block;
    font: 16px/1 "open_sansregular"; // <-- reset the font property
    max-width: 100%;
    vertical-align: middle; // vertical alignment of the inline element
  }
}

.vertical-center::before {    // create a full-height inline block pseudo=element
  content: ' ';
  display: inline-block;
  height: 100%;
  vertical-align: middle;  // vertical alignment of the inline element
}

@media (max-width: 768px) {
  .vertical-center::before {
    // height: auto;
    display: none;
  }
}


// the sign in form
.form-centered {
  margin: 0 auto;
  max-width: 330px;
  padding: 15px;

  img {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  small {
    display: block;
  }

  .input-group-prepend {
    margin-bottom: 20px;

    label {
      font-weight: normal;
      margin-bottom: 15px;
    }

    .input-group-text {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .form-control {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .form-control {
    box-sizing: border-box;
    font-size: 16px;
    height: auto;
    padding: 10px;
    position: relative;
  }

  .form-control:focus {
    z-index: 2;
  }
}

