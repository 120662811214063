/* Generated by Font Squirrel (http://www.fontsquirrel.com) on March 9, 2016 */

@font-face {
  font-family: 'open_sansitalic';
  src: url('../fonts/opensans-italic-webfont.eot');
  src: url('../fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
  url('../fonts/opensans-italic-webfont.woff') format('woff'),
  url('../fonts/opensans-italic-webfont.ttf') format('truetype'),
  url('../fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansregular';
  src: url('../fonts/opensans-regular-webfont.eot');
  src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
  url('../fonts/opensans-regular-webfont.woff') format('woff'),
  url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
  url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold';
  src: url('../fonts/opensans-semibold-webfont.eot');
  src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
  url('../fonts/opensans-semibold-webfont.woff') format('woff'),
  url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
  url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold_italic';
  src: url('../fonts/opensans-semibolditalic-webfont.eot');
  src: url('../fonts/opensans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
  url('../fonts/opensans-semibolditalic-webfont.woff') format('woff'),
  url('../fonts/opensans-semibolditalic-webfont.ttf') format('truetype'),
  url('../fonts/opensans-semibolditalic-webfont.svg#open_sanssemibold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'open_sansregular';
}

em {
  font-family: 'open_sansitalic';
}

strong {
  font-family: 'open_sanssemibold';

  em {
    font-family: 'open_sanssemibold_italic';
  }
}